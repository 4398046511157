<template>
  <div class="clue-change">
    <Back title="线索转交"></Back>
    <main>
      <van-form @submit="onSubmit">
        <van-field v-model="fieldValue" is-link readonly name="招商经理" label="招商经理" placeholder="请选择招商经理"
          :rules="[{ required: true, message: '请选择招商经理' }]" @click="show = true" />
        <van-field v-model="remarkContent" rows="4" autosize name="remarkContent" label="备注原因" type="textarea"
          maxlength="100" placeholder="请输入备注原因" show-word-limit clearable />
        <!-- 提交 -->
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
      <van-popup v-model="show" round position="bottom">
        <van-cascader v-model="cascaderValue" title="请选择招商经理" :options="options" @close="show = false"
          @finish="onFinish" />
      </van-popup>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { Toast } from "vant";
import {
  getClueInfo,
  getConsultantList,
  getClueChange,
} from "@/api/clue/clue"
export default {
  components: { Back },
  data() {
    return {
      clueId: this.$route.query.clueId,
      show: false,
      clueInfo: "",
      fieldValue: '',
      cascaderValue: '',
      deptId: "",//部门id
      consultantUserId: "",//招商经理id
      remarkContent: '',//备注原因
      // 选项列表，children 代表子选项，支持多级嵌套
      options: [],
    };
  },
  created() {
    this.getClueInfo();
    this.getConsultantList();
  },
  methods: {
    // 【请求】获取客户详情
    getClueInfo() {
      let clueId = this.clueId;
      getClueInfo(clueId).then((res) => {
        if (res.success) {
          this.clueInfo = res.data;
          console.log('this.clueInfo: ', this.clueInfo);
        }
      });
    },


    // 【请求】获取部门-招商经理二级联动列表
    getConsultantList() {
      let data = {
        roleCode: [3010, 3011]
      };
      getConsultantList(data).then((res) => {
        if (res.success) {
          let list = res.data;
          this.options = JSON.parse(JSON.stringify(list).replace(/deptId/g, "value").replace(/deptName/g, "text").replace(/empDtos/g, "children").replace(/empId/g, "value").replace(/empName/g, "text")) //修改对象属性名
        }
      });
    },


    // 【监听】招商经理选择
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.deptId = selectedOptions[0].value;
      this.consultantUserId = selectedOptions[1].value;
    },


    // 【监听】提交
    onSubmit(values) {
      let data = {
        clueId: this.clueId,
        remarkContent: values.remarkContent,
        consultantUserId: this.consultantUserId,
        oldConsultantUserId: this.clueInfo.consultantUserId,
        clueStatus: this.clueInfo.clueStatus,
        deptId: this.deptId

      }
      getClueChange(data).then(res => {
        if (res.success) {
          Toast('转交成功');
          this.$router.push("/clue/clueList");
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.clue-change {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}
</style>